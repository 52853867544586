<template>
  <div class="avatar" :class="{ 'avatar--premium': isPremium }">
    <v-avatar
      class="avatar-img"
      v-if="src || userStore?.user?.avatar?.preview"
      :size="size"
      :image="src ? src : userStore?.user?.avatar?.preview"
    ></v-avatar>
    <IconAvatar
      v-else
      class="avatar-img"
      :width="`${size}px`"
      :height="`${size}px`"
      :class="{
        'avatar--white': white,
      }"
    />
    <div class="avatar-premium" v-if="isPremium">
      Premium <v-icon class="avatar-premium__icon">mdi-medal-outline</v-icon>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconAvatar from "@/components/Icons/IconAvatar.vue";
const userStore = useUserStore();

const props = defineProps({
  size: {
    type: Number,
    default: 56,
  },
  white: {
    type: Boolean,
    default: false,
  },
  src: {
    type: String,
    default: "",
  },
  isPremium: {
    type: Boolean,
    default: false,
  },
});
</script>
<style lang="scss">
.avatar--white path {
  fill: #fff !important;
}

.avatar--premium {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .avatar-img {
    border: 2px solid var(--color-main-success);
  }

  .avatar-premium {
    color: var(--color-main-success);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;

    &__icon {
      font-size: 12px;
    }
  }
}
</style>
